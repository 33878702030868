window.addEventListener('beforeinstallprompt', (e) => {
  console.log("beforeinstallprompt");
  e.preventDefault();
  window.deferredPrompt = e;
});



export default {
  init() {
    // console.log("debug load", window.debugLoad);

  },
  needsToSeePrompt() {
    return (!window.matchMedia('(display-mode: standalone)').matches);
  },
  isIOS() {
    let isIOS = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
    console.log(isIOS);
    return isIOS;
  },

  install () {
    return new Promise((resolve, reject)=>{

      if (!window.deferredPrompt) {
        reject("manca deferredPrompt")
      }
      window.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          resolve({success:true});
        } else {
          resolve({success:false});
        }
      });
    });
  }
}
